@tailwind base;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@tailwind components;

.link-active-desktop {
  @apply border-yellow-500
  text-gray-900
  inline-flex
  inline-flex
  items-center
  px-1
  pt-1
  border-b-2
  text-sm
  font-medium;
}

.link-inactive-desktop {
  @apply border-transparent
  text-gray-500
  hover:border-gray-300
  hover:text-gray-700
  inline-flex
  items-center
  px-1
  pt-1
  border-b-2
  text-sm
  font-medium;
}

.link-active-mobile {
  @apply bg-yellow-50
  border-yellow-500
  text-yellow-700
  block
  pl-3
  pr-4
  py-2
  border-l-4
  text-base
  font-medium
  sm:pl-5
  sm:pr-6;
}

.link-inactive-mobile {
  @apply border-transparent
  text-gray-500
  hover:bg-gray-50
  hover:border-gray-300
  hover:text-gray-700
  block
  pl-3
  pr-4
  py-2
  border-l-4
  text-base
  font-medium
  sm:pl-5
  sm:pr-6;
}

.event-card > ul {
  @apply mt-2 mb-4;
}

.event-card > ul > li {
  @apply list-disc list-inside;
}

@tailwind utilities;
